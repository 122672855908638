@import "../../mixins.scss";

.home-section {
  @include flex();
  padding: 10%;
  position: relative;
  margin: -80px 0 -80px 0;

  .left {
    flex: 1;
    padding-right: 20px;
  }

  .right {
    flex: 1;
    padding-left: 20px;
    h2 {
      @include heading2();
      color: var(--primary-text);
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .label {
      @include para($fw: 400);
      font-size: 1.8rem;
      color: var(--primary-text);
      line-height: 29px;
      margin-bottom: 8px;
      span {
        display: block;
        @include para($fw: 400);
        font-size: 1.8rem;
        color: var(--primary-text);
        line-height: 29px;
      }
    }

    .desc {
      @include para();
      color: var(--primary-text);
      margin-bottom: 20px;
      line-height: 29px;
      span {
        display: block;
        @include para();
        color: var(--primary-text);
        margin-top: 8px;
        line-height: 29px;
      }
    }

    button {
      @include button();
    }
  }

  img[alt="circle-icon"] {
    width: 150px;
    height: auto;
    position: absolute;
    top: -100px;
    left: 40px;
  }

  @media (max-width: 900px) {
    margin: 80px 0 80px 0;
    padding: 0 3%;
    flex-direction: column;
    .left {
      margin-bottom: 60px;
      padding: 0;
    }
  }
}
